/* INDEX */
/* 1. General */
/* 1.1 Form */
/* 2. Menu */
/* 3. Customer assurance */
/* 4. Background section / parallax background section */
/* 5. Home categories */
/* 6. Brand Carousel */
/* 7. Product carousel - ARPL module */
/* 8. Slider - Module OPXslider */
/* 9. Product */
/* 9.1 Product card - Product miniature */
/* 9.2 Product page */
/* 9.3 Product listing */
/* 9.4 Product add to cart */
/* 10. Promo home - Module opxpromoimages */
/* 11. Promo home - Module opximageblock */
/* 12. Footer */
/* 13. CMS */
/* 14. Auth */
/* 15. Modules */
/* 15.1 Doofinder */
/* 15.2 Social login */
/* 16. Contact */
/* 17. Checkout */
/* 18. Blog */
/* 19. Brands */

html.alpifarma {

    /* 1. General */
    .container-fluid,
    .layout-container {

        @include media-breakpoint-up(xl) {
            padding-left: 0;
            padding-right: 0;
            max-width: 95vw;
            margin: 0 auto;
        }

        @media screen and (min-width: 1600px) {
            padding-left: 0;
            padding-right: 0;
            max-width: 90vw;
        }

        @media screen and (min-width: 2000px) {
            padding-left: 0;
            padding-right: 0;
            max-width: 85vw;
            margin: 0 auto;
        }

        @media screen and (min-width: 2400px) {
            max-width: 80vw;
        }
    }

    .layout-container {
        padding: 0;
    }

    .h2 {
        text-transform: none;
    }

    .btn:not(.search-widget__btn):not(.js-touchspin) {
        border-radius: 50rem !important;

        &.btn-secondary {
            color: #fff;
        }

        &.btn-white {
            color: #101010;

            &:hover {
                color: #fff;
            }
        }

        &.btn-muted {
            background-color: #b7b7b7;
            border: 1px solid #b7b7b7;
            color: white;
        }
    }

    /* 1.1 Form */
    .input-group {
        .btn[data-action=show-password] {
            padding: 7.5px 20px;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            border-top-right-radius: 1rem !important;
            border-bottom-right-radius: 1rem !important;
            background-color: $secondary;
            border-color: $secondary;
            color: #fff;
        }
    }

    /* 2. Menu */

    .header__logo{
        .logo{
            width: 100%;
            max-width: 160px;
            margin: 4px 0;
            @media screen and (min-width:992px) {
                max-width: 200px;
                margin: 0;
            }
        }
    }

    .user-info,
    .cart-preview {
        .u-link-body {
            transition: .5s;

            &:hover {
                color: $primary;
            }
        }
    }


    .amega-menu {
        .amenu-item.mm-22{
            padding: 13px 12px;
            a{
                background: #ffb142;
                padding: 4px 10px;
                display: inline-block;
                height: fit-content;
                line-height: normal;
                border-radius: 20px;
            }
        }
    }
    .mobile-amega-menu{
        .amenu-item.mm-22{
            a{
                padding: 10px 21px 21px 23px;
                span{
                    background: #ffb142;
                    padding: 4px 10px;
                    display: inline-block;
                    height: fit-content;
                    line-height: normal;
                    border-radius: 20px;
                    color: white;
                }
            }
        }
    }

    .amega-menu {
    
        position: relative;
        width: 100%;        

        .amenu-item:not(.mm-6) {
            position: inherit !important;
        }

        a {
            &.amenu-link {
                position: relative;

                @media screen and (min-width: 1200px) and (max-width: 1399.9px) {
                    font-size: 11.5px;
                    padding: 21px 10px;
                    letter-spacing: -.2px;
                }

                @media screen and (min-width: 1400px) and (max-width: 1499.9px) {
                    font-size: 12px;
                    padding: 21px 12px;
                    letter-spacing: -.2px;
                    
                }

                @media screen and (min-width: 1500px) and (max-width: 1599.9px) {
                    font-size: 12px;
                    padding: 21px 18px;
                }

                @media screen and (min-width: 1600px) and (max-width: 1699.9px) {
                    font-size: 12.5px;
                }

                @media screen and (min-width: 1700px) {
                    font-size: 14px;
                    padding: 21px;
                }
            }
        }

        .adropdown {
            min-width: 500px;
            &.adropdown-2,
            &.adropdown-3,
            &.adropdown-4,
            &.adropdown-5,
            &.adropdown-6,
            &.adropdown-7 {
                transform: none !important;
                position: absolute !important;
                left: 0 !important;
                width: 100% !important;

                .acontent-2{
                    .categories-grid{
                        .category-item {
                            &.level-1{
                                width: 100%;
                                justify-content: start;

                                @media screen and (min-width: 1200px) and (max-width: 1399.9px) {
                                    max-height: 300px;
                                }                
                                @media screen and (min-width: 1400px) and (max-width: 1499.9px) {
                                    max-height: 300px;                                    
                                }                
                                @media screen and (min-width: 1500px) and (max-width: 1599.9px) {
                                    max-height: 320px;
                                }
                                @media screen and (min-width: 1600px) and (max-width: 1699.9px) {
                                    max-height: 340px;
                                }
                                @media screen and (min-width: 1700px) {
                                    max-height: 360px;
                                }    
                            }
                            &.level-2{
                                padding-right: 40px;
                            }
                        }                        
                    }
                }                            
                
            }

            .level-1 {
                padding: 0;

                a {
                    padding: 30px 40px;
                    font-weight: bold;
                    display: block;
                    padding: 0;
                    margin: 5px 0;
                }
            }

            .level-2,
            .level-3,
            .level-4 {
                padding: 0;

                a {
                    padding: 30px 40px;
                    font-weight: normal;
                    display: block;
                    padding: 0;
                    margin: 5px 0;
                }
            }

            .level-3 {
                opacity: .75;
            }

            .level-4 {
                opacity: .5;
            }

            @media screen and (min-width: 1200px) and (max-width: 1399.9px) {
                .h5 a, p a{
                    font-size: 11.5px;
                    margin: 1px 0;
                }
            }
            @media screen and (min-width: 1400px) and (max-width: 1499.9px) {
                .h5 a, p a{
                    font-size: 12px;
                      margin: 2px 0;
                }
            }
            @media screen and (min-width: 1500px) and (max-width: 1599.9px) {
                .h5 a, p a{
                    font-size: 12px;
                      margin: 3px 0;
                }
            }
            @media screen and (min-width: 1600px) and (max-width: 1699.9px) {
                .h5 a, p a{
                    font-size: 12.5px;
                      margin: 3px 0;
                }
            }
            @media screen and (min-width: 1700px) {
                .h5 a, p a{
                    font-size: 14px;
                    margin: 3px 0;
                }
            }
        }
        .categories-grid {
            .category-item.level-1:nth-child(2), 
            .category-item.level-1:nth-child(3), 
            .category-item.level-1:nth-child(4){
                & > p.h5{
                    margin-top: 6px;
                }
            }
        }
    }

    #_desktop_top_menu {
        padding: 10px 0;

        ul {
            li {
                a {
                    color: white;
                }
            }
        }

        .menu-sub__content {
            max-width: 1000px;
            margin: 0 auto;

            ul {
                li {
                    a {
                        color: #222222;
                    }
                }
            }
        }
    }

    .amenu-item {
        .amenu-link {
            @media screen and (min-width:1200px) {
                color: white;
            }
        }
    }

    .cart-notification-circle {
        display: block;
        position: absolute;
        top: -16px;
        right: -10px;
        width: 18px;
        height: 18px;
        background-color: rgba(255, 177, 66, 1);
        overflow: hidden;
        border-radius: 50%;
        font-size: 10px;
        line-height: 18px;
        font-weight: bold;
        text-align: center;
        animation: pulse 2s infinite;

        @keyframes pulse {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
            }

            70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
            }

            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
            }
        }

        .cart-products-count {
            line-height: 18px;
        }
    }

    .header-top {
        padding: 0;

        @media screen and (min-width:992px) {
            padding: 15px;
        }
    }

    .menu-promo-shipping {
        font-size: 12px;
    }

    .search-widget {
        margin: 0;
    }

    .menu-search-container {
        background-color: $primary;
        text-align: center;
        padding: 15px;

        .search-widget {
            margin: 0 auto !important;
        }

        @media screen and (min-width:992px) {
            padding: 0;
            background-color: transparent;
            text-align: right;

            .search-widget {
                margin-left: auto !important;
                margin-right: 15px !important;
            }
        }
    }

    #menu-icon {
        min-width: 50px;
        padding-left: 0;
    }

    /* 3. Customer assurance */
    .section-customer-reassurance {
        img {
            filter: invert(1);
            max-width: 48px;
            margin-bottom: 10px;

        }
    }

    #product {
        .section-customer-reassurance {
            border: 1px solid #f1f1f1 !important;
            background: none !important;
            margin-top: 15px;

            .section-customer-reassurance-column {
                width: 100%;
                max-width: 100%;
                width: 100%;
                flex: 0 0 100%;
                display: flex;
                align-items: center;
            }

            img {
                filter: invert(29%) sepia(98%) saturate(449%) hue-rotate(113deg) brightness(100%) contrast(101%);
            }

            h4 {
                color: #000 !important;
                text-align: left;
                padding: 0 20px;
                font-size: 14px;
            }
        }
    }

    #cart, #checkout {
        .blockreassurance_product {
            padding: 20px;
            border: 1px solid #efefef;
            border-radius: 1rem;
            display: block;
            margin-top: 0;

            .item-product {
                margin-right: 10px;
                height: 40px;
                width: 40px;
            }

            .block-title {
                margin: 10px 0;
            }
        }
    }

    /* 4. Background section / parallax background section */
    .section-background {
        position: relative;
        z-index: 0;
        color: #fff;

        .section-background-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            top: 0;
            left: 0;
            background-color: rgba($color: #000000, $alpha: .2);
        }

        .section-background-image {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            z-index: 1;
            top: 0;
            left: 0;
        }

        .section-background-content {
            position: relative;
            z-index: 3;
            padding: 120px 0;

            p {
                font-size: 16px;
            }
        }
    }

    /* 5. Home categories */
    .section-home-categories {
        .category-title {
            background: $primary;
            margin-top: -45px;
            z-index: 2;
            position: relative;
            width: 50%;
            padding: 6px 14px;
            color: #fff;
            margin-bottom: 30px;
        }

        .subcategory-title {
            color: $secondary;
            font-weight: 600;
            margin-left: 14px;
            font-size: 14px;
            margin-top: 1rem !important;
            line-height: 23px;
        }
    }


    /* 6. Brand Carousel */
    .carousel-brands {
        position: relative;

        .slick-arrow {

            &.slick-arrow-next,
            &.slick-arrow-prev {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 40px;
                height: 40px;
                background-color: #fff;
                border-radius: 50%;
                box-shadow: 1px 1px 3px rgba($color: #000000, $alpha: .4);
                text-align: center;
                transition: .5s;
                opacity: .8;
                cursor: pointer;

                &:hover {
                    width: 42px;
                    height: 42px;
                    opacity: 1;
                }
            }

            &.slick-arrow-next {
                right: 0;

                &::after {
                    content: 'chevron_right';
                }
            }

            &.slick-arrow-prev {
                left: 0;

                &::after {
                    content: 'chevron_left';
                }
            }
        }

        &.no-js {
            display: flex;
            overflow: scroll;

            img {
                width: 20vw !important
            }
        }

        @media screen and (min-width:1600px) {
            img {
                padding: 0 24px !important;
            }
        }
    }

    .section-brands-carousel-image {
        aspect-ratio: 1;
        object-fit: contain;
        max-width: 160px;
    }


    /* 7. Product carousel - ARPL module */    
    #arpl-group-1 {
        background-color: $light;

        .arpl-section {
            background-color: transparent;
            border: none;
        }
    }

    .arpl-section{
        padding: 0;
    }

    .arpl-group {
        .container-fluid{
            padding: 0;
        }
        h2 {
            color: $primary;
        }

        .owl-nav {

            .owl-next,
            .owl-prev {
                width: 40px !important;
                height: 40px !important;
                background-color: #fff !important;
                color: #000000 !important;
                border-radius: 50% !important;
                box-shadow: 1px 1px 3px rgba($color: #000000, $alpha: .4);
                text-align: center;
                transition: .5s;
                opacity: .8;
                cursor: pointer;

                &:hover {
                    width: 42px !important;
                    height: 42px !important;
                    opacity: 1;
                }

                svg {
                    display: none !important;
                }

                &::after {
                    font-family: Material Icons;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 1.32rem;
                }
            }

            .owl-next {
                &::after {
                    content: 'chevron_right';
                }
            }

            .owl-prev {
                &::after {
                    content: 'chevron_left';
                }
            }
        }
    }


    /* 8. Slider - Module OPXslider */
    @media screen and (min-width: 1200px) {
        #dSlider {
            .dSlider {
                .dSlider-slide {
                    height: 480px !important;
                    .dOverlay{
                        background: transparent;
                        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 35%, rgba(0,0,0,.1) 50%, rgba(255,255,255,0) 65%, rgba(255,255,255,0) 100%); 
                    }
                }
            }
        }        
    }

    #dSlider {
        .slick-active {
            .dSlider-content {
                padding: 10px 0 30px 0;
            }
        }
    }

    /* 9. Product */
    /* 9.1 Product card - Product miniature */
    .product-miniature {
        .product-description {
            .product-title {
                a {
                    font-size: 14px;
                    text-transform: none;
                }
            }
        }
    }

    .product-description {
        .product-title {
            display: block;
            height: 82px;
            overflow: hidden;
            a{
                font-size: 13px;
            }
        }

        .product-price-and-shipping {
            .price:not(.regular-price) {
                font-weight: 700;
                font-size: 1.2rem;

                &.current-price-discount {
                    color: #206d4e;
                    font-weight: 700;
                    font-size: 1.2rem;
                }
            }

            .regular-price {
                color: #7a7a7a;
                font-size: .7875rem;
                font-weight: 700;
                text-decoration: line-through;
            }
        }
    }

    /* 9.2 Product page */
    #product {

        .section-customer-reassurance {
            border-radius: 1rem;
        }

        .layout-container {
            max-width: 100%;
            margin-bottom: 0 !important;
        }

        .wishlist-button-add {
            display: none;
        }

        .product-prices {
            .price {

                &.current-price-discount,
                &.current-price-display {
                    font-size: 32px;
                }
            }
        }

        .product-discount {
            font-size: 21px;
        }

        .discount:not(.product-flag) {
            color: #fff;
            background-color: #206d4e;
            display: inline-block;
            padding: .25rem;
            font-size: 1.1rem;
        }

        .regular-price {
            font-size: .85rem;
            font-weight: 400;
        }

        .product-name:not(.modal-cart__name) {
            font-size: 1.2375rem !important;
            text-transform: none;
        }
    }

    /* 9.3 Product listing */
    #category, #search, #prices-drop, #new-products, #best-sales, #manufacturer {
        @media (min-width: 992px) {
            .product-miniature {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }

        @media (min-width: 1920px) {
            .product-miniature {
                flex: 0 0 20%;
                max-width: 20%;
            }
        }

        .filter-h2, .filter-span{
            font-size: 15px;
            line-height: 15px;
            font-weight: normal;
            margin-bottom: 0;
        }

        #amazzing_filter {
            .title_block {
                font-size: 21px !important;
                line-height: normal;
                font-weight: 700;
            }

            .af_filter {
                select {
                    border: 1px solid #D0D5DB;
                    border-radius: 8px;
                }
            }

            @media (min-width: 992px) {
                padding-right: 20px;
            }
        }
    }

    /* 9.4 Product add to cart */
    body {
        &.js-cart-modal-open {
            padding: 0 !important;

            .modal-backdrop {
                display: none;
            }
        }
    }

    #blockcart-modal {
        top: 0;
        right: 0;
        margin: 0;
        width: 100vw;
        z-index: 99999999;

        @media screen and (min-width:992px) {
            max-width: 400px;
        }

        left: auto;
        padding: 0 !important;

        .modal-dialog {
            max-width: 100%;
            width: 100%;
            margin: 0;

            .modal-content {
                height: 100vh;
                border-radius: 0;
            }
        }

        .modal-cart__image{
            max-width: 120px;
            aspect-ratio: 1;
            object-fit: contain;                
            @media screen and (min-width: 992px) {
                max-width: 180px;
            }
        }
    }

    /* 10. Promo home - Module opxpromoimages */
    #opxpromoimages_container {

        .opxpromoimages_image,
        .banner-promo {
            transition: .5s;

            &:hover {
                transform: scale(1.02);
            }
        }
    }

    /* 11. Promo home - Module opximageblock */
    .opximagesblock {
        transition: .5s;

        &:hover {
            transform: scale(1.02);
        }
    }

    /* 12. Footer */
    .footer-payment-section {
        .footer-payment {
            width: 54px;
            height: 30px;
            margin: 2px;
        }
    }

    /* 13. CMS */
    .cms-page-header {
        background-color: $secondary;
        padding: 125px 0;
        text-align: center;

        h1 {
            font-weight: bold;
            font-size: 32px;
            color: white;
        }
    }

    #cms {
        .breadcrumbs-nav {
            background: #6bcdb2;
            border-bottom: 1px solid rgba($color: #fff, $alpha: .1);

            ol {
                li {
                    &::before {
                        color: white;
                    }

                    color: white;

                    a {
                        color: white;
                    }
                }
            }
        }
    }

    /* 14. Auth */
    #authentication,
    #password,
    #registration {
        .layout-container {
            background-image: url("/img/cms/about-us/about-us-2022-4.jpg");
        }
        #fbpsc{
            max-width: 450px;
            margin: 0 auto;
              margin-top: 0px;
            padding: 0;
            margin-top: 0px;
            .btn-social{
                width: 100%;
                @media screen and (min-width:768px) {
                    width: auto;   
                }                
                max-width: 100%;
                min-width: none;
            }
        }
        .auth-socil-label{
            display: block!important;
        }
        .password-strength-feedback{
            display: block!important;
            i{
                color: #dc3545;
            }
        }
    }

    .password-strength-feedback{
        display: block!important;
        i{
            color: #dc3545;
        }
    }

    #my-account, #identity, #history, #order-slip, #module-psgdpr-gdpr, #module-ps_emailalerts-account{
        .layout-container{
            padding: 0 15px;
            @media screen and (min-width: 1200px) {
                padding: 0;
            }
        }
    }


    /* 15. Modules */
    /* 15.1 Doofinder */
    .section-doofinder-carousel {
        .dfwidget-host {
            background-color: transparent;
        }

        .dfwidget-carousel {
            position: relative;


            .carousel-content {
                gap: 0 20px;
            }

            .card-link {
                display: block;
                width: 100%;
                position: relative;
                background: $secondary;
                color: white;
                height: 50px;
                line-height: 50px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
            }

            .card-link::after {
                content: 'Scopri di più';
            }

            .dfwidget-card {
                border: none;
                overflow: hidden;
                border-radius: 1rem;
                border: 1px solid rgba(0, 0, 0, .125);
                padding: 0;
                width: 45%;

                @media screen and (min-width:512px) {
                    width: 50%;
                }

                @media screen and (min-width:768px) {
                    width: 23%;
                }

                @media screen and (min-width:1200px) {
                    width: 15.2%;
                }

                @media screen and (min-width:1800px) {
                    width: 12.8%;
                }
            }

            .card-price:not(.card-price--sale) {
                color: #7a7a7a;
                font-size: .7875rem;
                font-weight: 700;
                text-decoration: line-through;
                margin-left: 0;
            }

            .card-price--sale {
                font-size: 1.2rem !important;
                color: $primary;
                font-weight: 700;
            }

            .card-pricing,
            .card-content {
                text-align: center;
                margin: 0 auto;
            }

            .card-content .card-title {
                padding-left: 8px;
                padding-right: 8px;
            }

            .card-pricing {
                padding-bottom: 20px;
            }

            .card-title {
                font-size: 14px;
                color: #000;
                margin-top: 18px;
                display: inline-block;
            }
        }

        .carousel-arrow {

            &.carousel-arrow-right {
                position: absolute;
                right: 10px;
                display: block;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
                background: white;
                border-radius: 50%;
            }

            &.carousel-arrow-left {
                position: absolute;
                left: 10px;
                display: block;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
                background: white;
                border-radius: 50%;
            }
        }
    }

    /* 15.2 Social login */
    #fbpsc {
        padding-top: 0;
        padding-bottom: 50px;
        background: none!important;
        border: none!important;
        .btn-connect {
            padding: 14px 20px;
            text-transform: uppercase;
            font-size: .77rem;
            font-weight: 700;
            min-width: 120px;
            box-shadow: none;

            &.btn-facebook {
                background-color: transparent;
                border: 1px solid #3b5998;
                color: #3b5998;
                &> :first-child {
                    filter: invert(33%) sepia(9%) saturate(3316%) hue-rotate(183deg) brightness(60%) contrast(93%);
                }
                span {
                    color: #3b5998;
                }
                &:hover {
                    background-color: #3b5998;
                    border: 1px solid #3b5998;
                    color: #ffffff;
                    &> :first-child {
                        filter: none;
                    }
                    span {
                        color: #fff;
                    }
                }
            }

            &.btn-google {
                background-color: transparent;
                border: 1px solid #dd4b39;
                color: #dd4b39;
                &> :first-child {
                    filter: invert(57%) sepia(95%) saturate(4591%) hue-rotate(338deg) brightness(89%) contrast(93%);
                }
                span {
                    color: #dd4b39;
                }
                &:hover {
                    background-color: #dd4b39;
                    border: 1px solid #dd4b39;
                    color: #ffffff;
                    &> :first-child {
                        filter: none;
                    }
                    span {
                        color: #fff;
                    }
                }
            }
                
            &> :first-child {
                top: 12px;
            }

            span {
                font-size: .77rem;
            }
        }
    }

    /* 15.3 Scalapay */
    .scalapay-widget {
        margin-top: 15px;
    }

    #cart{
        .scalapay-widget {
            margin-bottom: 15px;
        }
    }

    /* 16. Contact */
    .contact-rich-info-circle {
        width: 75px;
        height: 75px;
        line-height: 75px;
        background: $primary;
        border-radius: 50%;
        margin: 0 auto;

        i {
            font-size: 30px;
            line-height: 75px;
            text-align: center;
            color: white;
        }
    }

    /* 17. Checkout */


    .checkout{
        @keyframes checkout-pulse {
            0% {
                box-shadow: 0 0 0 0 rgba($color:$primary, $alpha: .7);
            }
        
            70% {
                box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
            }
        
            100% {
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            }
        }
        &.cart-detailed-actions{
            .btn{
                box-shadow: 0 0 0 0 rgba($color:$primary, $alpha: 1);
                animation: checkout-pulse 2s infinite;
            }
        }
    }
    .checkout-form{
        form {
            @media screen and (min-width: 992px) {
                width: 100%;
                max-width: 600px;
            }
        }
    }
    .checkout-tabs{
        @media screen and (min-width: 992px) {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
        }
    }

    .payment-options {
        label{
            &.custom-control-label{
                width: 100%;
                img{
                    height: 30px;
                    object-fit: contain;
                }
            }
        }
        .payment-option{
            margin-bottom: 10px;
            .custom-control-label::before, .custom-control-label::after {
                top: 5px;
            }
        }
        .payplugPaymentOption{
            line-height: normal;
        }
    }

    #checkout-personal-information-step:not(.-current){
        #fbpsc{
            display: none;
        }
    }

    /* 18. Blog */
    .prestablog {
        .prestablog_more {
            display: none !important;
        }
    }

    .categorie_blog {
        color: $primary;
    }

    #prestablogfront,
    .prestablogExtra {
        border: none;
    }

    div.prestablog_pagination span.current {
        background-color: $primary;
    }

    #_prestablog_index {
        display: none;
    }

    #prestablog_menu_cat {
        .blog-menu {
            @media screen and (max-width:992px) {
                & {
                    width: 100%;
                }
            }

            ul {
                background-color: transparent !important;
                margin: 0;

                @media screen and (max-width:992px) {
                    & {
                        width: 100%;
                    }
                }

                li:first-of-type {
                    a {
                        padding: 9px;

                        i {
                            color: #206d4e;
                        }

                        &:hover {
                            background-color: $primary;
                            border: 2px solid $primary;
                            color: white;

                            i {
                                color: white;
                            }
                        }
                    }
                }

                li {
                    text-transform: uppercase;

                    a {
                        padding: 12px 20px;
                        font-size: 14px !important;
                        line-height: 14px !important;
                        background-color: transparent;
                        border: 2px solid $primary;
                        color: $primary;
                        margin: 2px;

                        &:hover {
                            background-color: $primary;
                            border: 2px solid $primary;
                            color: white;
                        }

                        @media screen and (max-width:992px) {
                            & {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .blog-related-product {
        a {
            .productslinks {
                img {
                    object-fit: contain;
                    aspect-ratio: 1/1;
                    object-position: center;
                }
            }
        }
    }

    /* 19. Brands */
    #manufacturer{
        .layout-container{
            padding: 0 15px;
        }
        @media screen and (min-width: 1200px) {
            .layout-container{
                padding: 0;
            }
        }
    }

}