.password-requirements {
  .material-icons.check-icon {
    display: none;
  }
  .is-valid {
    .material-icons.check-icon {
      display: inline-block;
    }
    .material-icons.close-icon {
      display: none;
    }
  }
}
