.product-description {

    line-height: 1.8;
    color: rgba($color: #000000, $alpha: .75);

    & > div{
        & > h2{
            margin-top: 0;
        }
    }

    h2 {
        font-size: 21px;
        margin-bottom: 15px;
        margin-top: 10px;
        font-weight: bold;
        color: $primary;
    }

    h3 {
        font-size: 18px;
        margin-bottom: 15px;
        margin-top: 10px;
        font-weight: bold;
        color: rgba($color: #000000, $alpha: 1);
    }

    h4 {
        font-size: 16px;
        margin-bottom: 15px;
        margin-top: 10px;
        font-weight: bold;
        color: rgba($color: #000000, $alpha: 1);
    }

    h5 {
        font-size: 15px;
        margin-bottom: 15px;
        margin-top: 10px;
        font-weight: bold;
        color: rgba($color: #000000, $alpha: 1);
    }

    h6 {
        font-size: 14px;
        margin-bottom: 15px;
        margin-top: 10px;
        font-weight: bold;
        color: rgba($color: #000000, $alpha: 1);
    }

    ul {
        list-style-position: inside;
    }

    table {
        border-collapse: collapse;
        min-width: 75%;
        margin-top: 15px;

        td {
            padding: 15px;
        }

        thead {
            td {
                background-color: #54585d;
                color: #ffffff;
                font-weight: bold;
                font-size: 13px;
                border: 1px solid #54585d;
                text-align: left;
            }
        }

        tbody {
            td {
                color: #636363;
                border: 1px solid #dddfe1;
                text-align: left;
            }

            tr {
                background-color: #f9fafb;
            }

            tr:nth-child(odd) {
                background-color: #ffffff;
            }
        }

    }

}